import { SOCIAL_LINKS } from "../constants"


function PageFooterSocial(props) {

    const name = SOCIAL_LINKS[props.name]?.name
    const url = SOCIAL_LINKS[props.name]?.url
    const image_src = SOCIAL_LINKS[props.name]?.img

    return (
        <div className="footer-social-container">
            <a href={url} target="_blank" rel="noreferrer">
                <img src={image_src} alt={name} />
            </a>
        </div>
    )
}

export default PageFooterSocial
