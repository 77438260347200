
function AttrChangeConfirmation(props) {

    return (
        <div className={`${!props.isBoxActive ? "active" : ""} show`}>
            <div className="change-confirmation-text">
                {props?.tx_hash}
            </div>
        </div>
    )
}

export default AttrChangeConfirmation;
