import { parseEther } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";
import { getMaoContract, getOpenSeaAssetUrl } from "../utils";


function MintButton(props) {
    const { account, active, library } = useWeb3React();

    const tokenToMint = props.tokenId;
    const tokenMintPrice = props.tokenPrice;
    const buttonText = props.buttonText;
    const mintEnabled = props.mintEnabled;
    
    const onClick = () => {
        if (mintEnabled) {
            mint();
        } else {
            openSeaTrade()
        }
    }

    async function mint() {
        if (!active) {
            alert("Connect wallet to mint")
            return;
        }
        const contract = getMaoContract(library, account);

        await contract.mintToken(tokenToMint, {
            value: parseEther(tokenMintPrice)
        })
            .then(console.log)
            .catch(error => {
                console.error(`${error?.code} - ${error?.data?.message}`)
            });
    }

    async function openSeaTrade() {
        window.open(getOpenSeaAssetUrl(tokenToMint), "_blank")
    }

    return (
        <div 
            type="button"
            className="mint-view-actions-mint"
            onClick={onClick}
            onMouseEnter={props.showToolTip}
            onMouseLeave={props.hideToolTip}
        >
            {buttonText}
        </div>
    )
}

export default MintButton;
