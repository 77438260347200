import { maoAbi } from '../abi/mao';
import { fortuneAbi } from '../abi/fortune';

export const MAO_ADDRESS = '0x3A062FC7eE9F28D19a4BC039D8C3D1fd2055035c'
export const FORTUNE_ADDRESS = '0xfC3a79B5eBcE162cc469E6A8C0d34fd9a7689d88'
export const MAO_ABI = maoAbi;
export const FORTUNE_ABI = fortuneAbi;

export const NFT_MAX_SUPPLY = 8888;
export const NFT_POOL_SIZE = 8888;

// Modifier Actions
export const CHANGE_NAME = "Change Name"
export const CHANGE_MESSAGE = "Change Message"
export const UPGRADE_MULTIPLIER = "Upgrade Multiplier"
export const CLAIM_FORTUNE = "Claim Fortune"

// Navigation Menu
export const NAV_MINT = "Mint"
export const NAV_FAQ = "FAQ"
export const NAV_GALLERY = "Gallery"
export const NAV_WALLET = "Wallet"

// GA
export const GA_TRACKING_ID_DEV = "UA-196130938-1"
export const GA_TRACKING_ID_PROD = "UA-196084366-1"

// Network
export const DEV_CHAIN_IDS = [
    1, // Mainnet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    42, // Hardhat
]
export const PROD_CHAIN_IDS = [1]

// Social Links
export const SOCIAL_LINKS = {
    opensea: {
        name: "OpenSea",
        url: "https://opensea.io/collection/getmao",
        img: "/images/opensea-logo.png",
    },
    twitter: {
        name: "Twitter",
        url: "https://twitter.com/mao_nft",
        img: "/images/twitter-logo.png",
    },
    discord: {
        name: "Discord",
        url: "https://discord.gg/mxywPFPV",
        img: "/images/discord-logo.png",
    },
    instagram: {
        name: "Instagram",
        url: "https://www.instagram.com/mao_nft/",
        img: "/images/instagram-logo.png",
    },
    etherscan: {
        name: "Etherscan",
        url: "https://etherscan.io/address/0x3A062FC7eE9F28D19a4BC039D8C3D1fd2055035c#readContract",
        img: "/images/etherscan-logo.png",
    }
}
