import { formatEther } from "@ethersproject/units";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { formatMaoName, getImageUrl, getMaoContract, getOfflinePrice, isValidAddress } from "../../utils";
import MintButton from "../MintButton";

function MaoView(props) {

    const { active, account, library } = useWeb3React();
    const [ tokenPrice, setTokenPrice ] = useState("undefined");
    const [ maoOwner, setMaoOwner ] = useState(null)
    const [ maoName, setMaoName ] = useState("Unnamed")

    const params = useParams() // Get /1 from URL
    const tokenId = params.id

    function setOfflinePrice(tokenId) {
        console.warn(`Falling back to offline price...`)
        setTokenPrice(getOfflinePrice(tokenId))
    }

    useEffect(() => {
        if (!active) {
            setOfflinePrice(tokenId)
            return // Immediately return
        }

        const contract = getMaoContract(library, account)
        contract.priceByTokenId(tokenId)
            .then(x => {
                const amount = formatEther(x);
                setTokenPrice(amount)
            })
            .catch(error => {
                console.error(error)
                setOfflinePrice(tokenId)
            })

        // Update Mao mintable state while on sale
        contract.ownerOf(tokenId)
            .then(address => {
                if (isValidAddress(address)) {
                    console.log(`${address} owns Mao #${tokenId}`)
                    setMaoOwner(address)
                } else {
                    setMaoOwner(null)
                    throw new Error(`Invalid address: ${address}`)
                }
            })
            .catch(error => {
                if (error?.message?.includes("nonexistent token")) {
                    console.warn(`Mao #${tokenId} is unowned`)
                } else {
                    console.error(error)
                }
            })

        contract.nameByTokenId(tokenId)
            .then(_maoName => {
                setMaoName(formatMaoName(_maoName))
            })
            .catch(error => {
                console.error(error)
            })



    }, [active, account, library])

    // Calculate final states
    const mintButtonText = maoOwner ? "Trade on OpenSea" : "Mint!"
    const ownershipStatus = maoOwner ? `Owner: ${maoOwner}` : null

    return (
        <div className="mao-view-container">
            <div className="mao-image-container">
                <img src={getImageUrl(tokenId)} alt="mao-gif" />
                <div className="mao-image-container-details">
                    <h3>MAO #{tokenId}: {maoName}</h3>
                    <p>{ownershipStatus}</p>
                    {/* <div>Multiplier: {props.multiplier}, Fortune: {fortune}</div> */}
                    {/* <p className="wallet-image-container-detail-message">
                        {props.message}
                    </p> */}
                </div>
            </div>

            <div className="mao-view-actions">
                <div className="mao-view-actions-price">
                    {`Ξ ${tokenPrice}`}
                </div>

                <MintButton 
                    tokenId={tokenId} 
                    buttonText={mintButtonText}
                    tokenPrice={tokenPrice}
                    mintEnabled={maoOwner ? false : true}
                    // hideToolTip={hideToolTip}
                    // showToolTip={showToolTip}
                />
            </div>
        </div>
    )
}

export default MaoView;
