import { useState } from "react";

function Question(props) {
    const [ isActive, setActive ] = useState(false);

    function handleToggle() {
        setActive(!isActive)
    }

    const myStyle = {
        maxHeight: isActive ? "300px" : "0",
    }

    return (
        <div className="faq-container">
            <div className={"faq-accordion " + (isActive ? "active" : "")} onClick={handleToggle}>
                {props.question}
            </div>
            <div className={"faq-panel"} style={myStyle}>
                {props.answer}
            </div>
        </div>
    )
}

export default Question
