import NavButton from "./components/NavButton";
import ContentView from "./components/ContentView";
import WalletButton from "./components/WalletButton";
import { NAV_FAQ, NAV_WALLET } from "./constants";
import { Link, useLocation } from "react-router-dom";

const NAV_NAMES = [
  NAV_WALLET,
  NAV_FAQ,
]

function App(props) {

  const { pathname } = useLocation();

  const navList = NAV_NAMES.map(name => {
    // pathname is /gallery while nav_name is Gallery
    const isPressed = pathname.includes(name.toLowerCase())
      
    return (
      <NavButton
        key={name}
        name={name}
        isPressed={isPressed}
      />
    )
  });

  return (
      <div className="app-background">

        <div className="app-background-header">

          <div className="app-background-header-title">
              <Link to="/">
                <h1>MAO</h1>
              </Link>
            </div>

          <div className="nav-bar">
            {navList}
            <WalletButton />
          </div>

        </div>

        <ContentView />

      </div>
  );
}

export default App;
