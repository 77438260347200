import { useWeb3React } from '@web3-react/core';
import { InjectedConnector, NoEthereumProviderError } from '@web3-react/injected-connector';
import { isMobile } from 'react-device-detect';
import { getSupportedChainIds, truncateAddress } from '../utils';

export const injectedConnector = new InjectedConnector({
    supportedChainIds: getSupportedChainIds()
});

export const WalletButton = () => {
    const { chainId, account, activate, active } = useWeb3React();

    const onClick = () => {
        activate(injectedConnector, undefined, true).catch((e) => {
            if (e instanceof NoEthereumProviderError) {
                const requirement = isMobile ? "MetaMask Mobile" : "MetaMask extension"
                alert(`Please install ${requirement} to connect!`)
            } else {
                console.error(e)
            }
        })
    }

    if (!active) {
        return (
            <div className="metamask-wallet">
                <div className="metamask-wallet-connect" type="button" onClick={onClick}>
                    Connect
                </div>
            </div>
        );
    } else {
        const badgeId = (chainId === 1) ? "metamask-wallet-account" : "metamask-wallet-account-unsupported";
        return (
            <div className="metamask-wallet">
                <div className={badgeId}>{truncateAddress(account)}</div>
            </div>
        );
    }
};

export default WalletButton;
