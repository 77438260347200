import { useWeb3React } from "@web3-react/core";

function WalletImageButton(props) {
    const { active } = useWeb3React();

    const onClick = () => {
        if (!active) {
            alert("Please connect wallet");
        }

        props.button_action()
    }

    return (
        <div 
            className="wallet-image-container-actions-button"
            onClick={onClick}
        >
            {props.action_name}
        </div>
    )
}

export default WalletImageButton;
