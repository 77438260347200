import { useWeb3React } from "@web3-react/core"
import { useState } from "react";
import { CHANGE_MESSAGE, CHANGE_NAME, CLAIM_FORTUNE, UPGRADE_MULTIPLIER } from "../../constants";
import { getMaoContract, getFortuneContract } from "../../utils";
import AttrChangeConfirmation from "../AttrChangeConfirmation";
import AttrChangeForm from "../AttrChangeForm";
import WalletImageButton from "./WalletImageButton";

function WalletImage(props) {

    const [ isMessageFormActive, setIsMessageFormActive ] = useState(false);
    const [ isNameFormActive, setIsNameFormActive ] = useState(false);
    const [ isConfirmationActive, setIsConfirmationActive ] = useState(false);
    const [ txHash, setTxHash ] = useState("");
    const { account, library } = useWeb3React();
    const tokenId = props.token_id

    function toggleNameForm() {
        setIsNameFormActive(!isNameFormActive)
        if (isMessageFormActive) {
            setIsMessageFormActive(false)
        }
    }

    function toggleMessageForm() {
        setIsMessageFormActive(!isMessageFormActive)
        if (isNameFormActive) {
            setIsNameFormActive(false)
        }
    }

    function removeForms() {
        setIsMessageFormActive(false);
        setIsNameFormActive(false);
    }

    function toggleConfirmation() {
        setIsConfirmationActive(true)
        setTimeout(() => {
            setIsConfirmationActive(false)
            setTxHash("")
        }, 10000)
    }

    async function changeName(newName) {
        const contract = getMaoContract (library, account);
        console.log(`Changing name for ${tokenId}`)
        const tx = await contract.changeName(tokenId, newName)
                .catch(err => {console.error(err?.data?.message)});
        
        if (tx) {
            setTxHash(tx.hash)
            toggleConfirmation()
        }
    }

    async function changeMessage(newMessage) {
        const contract = getMaoContract(library, account);
        console.log(`Changing msg for ${tokenId}`)
        const tx = await contract.changeMessage(tokenId, newMessage)
                .catch(err => {console.error(err?.data?.message)});
        
        if (tx) {
            setTxHash(tx.hash)
            toggleConfirmation()
        }
    }

    async function upgradeMultiplier() {
        removeForms()
        const contract = getMaoContract(library, account);
        console.log(`Upgrading multiplier for ${tokenId}`)
        const tx = await contract.upgradeMultiplier(tokenId)
                .catch(err => {console.error(err?.data?.message)})

        if (tx) {
            setTxHash(tx.hash)
            toggleConfirmation()
        }
    }

    async function claimFortune() {
        removeForms()
        const fortuneContract = getFortuneContract(library, account);
        console.log(`Claiming fortunes for ${tokenId}`)
        const tx = await fortuneContract.claim([tokenId])
                .catch(err => {console.error(err?.data?.message)});
        
        if (tx) {
            setTxHash(tx.hash)
            toggleConfirmation()
        }
    }

    const fortune = parseFloat(props.fortune_token).toFixed(2)

    function formDetails() {
        if (isNameFormActive) {
            return {
                key: CHANGE_NAME,
                action_name: CHANGE_NAME,
                isFormActive: isNameFormActive,
                toggleForm: toggleNameForm,
                submitAction: changeName
            }

        } else if (isMessageFormActive) {
            return {
                key: CHANGE_MESSAGE,
                action_name: CHANGE_MESSAGE,
                isFormActive: isMessageFormActive,
                toggleForm: toggleMessageForm,
                submitAction: changeMessage
            }

        } else {
            return {}
        }
    }

    function activeForm() {
        return (isNameFormActive | isMessageFormActive) ? (
            <AttrChangeForm 
                formDetails={formDetails()}
            />
        ) : null;
    }

    return (
        <div className="wallet-image-container">
            <img src={props.img_src} alt="mao-gif" />
            <div className="wallet-image-container-details">
                <h3>MAO #{props.token_id}: {props.mao_name}</h3>
                <div>Multiplier: {props.multiplier}, Fortune: {fortune}</div>
                <p className="wallet-image-container-detail-message">
                    {props.message}
                </p>
            </div>

            <div className="wallet-image-container-actions">
                <WalletImageButton 
                    key={CHANGE_NAME}
                    action_name={CHANGE_NAME}
                    image_props={props}
                    toggleForm={toggleNameForm}
                    button_action={toggleNameForm}
                />
                <WalletImageButton 
                    key={CHANGE_MESSAGE}
                    action_name={CHANGE_MESSAGE}
                    image_props={props}
                    toggleForm={toggleMessageForm}
                    button_action={toggleMessageForm}
                />
                <WalletImageButton 
                    key={UPGRADE_MULTIPLIER}
                    action_name={UPGRADE_MULTIPLIER}
                    image_props={props}
                    button_action={upgradeMultiplier}
                />
                <WalletImageButton 
                    key={CLAIM_FORTUNE}
                    action_name={CLAIM_FORTUNE}
                    image_props={props}
                    button_action={claimFortune}
                />
            </div>

            {activeForm()}

            <AttrChangeConfirmation 
                isBoxActive={isConfirmationActive}
                tx_hash={txHash}
            />

        </div>
    )
}

export default WalletImage;
