import MintView from "./MintView";
import FaqView from "./faq/FaqView";
import WalletView from "./wallet/WalletView"
import GalleryView from "./gallery/GalleryView";

import ReactGA from 'react-ga';
import { getTrackingId } from "../utils";
import { Route, Switch, useLocation } from "react-router";
import React, { useEffect } from "react";
import MaoView from "./mao/MaoView";
import HomeView from "./HomeView";

ReactGA.initialize(getTrackingId())


function ContentView(props) {

    // e.g. /mint
    const { pathname } = useLocation(); 

    useEffect(() => {
        ReactGA.pageview(pathname)
    }, [pathname])

    return (
        <div className="content-container">
            <Switch>
                <Route exact path="/" component={MintView} />
                <Route exact path="/mint" component={MintView} />
                {/* <Route exact path="/info" component={HomeView} /> */}
                <Route exact path="/faq" component={FaqView} />
                <Route path="/gallery" component={GalleryView} />
                <Route exact path="/wallet" component={WalletView} />
                <Route path="/mao/:id" component={MaoView} />
            </Switch>
        </div>
    )
}

export default ContentView;
