import { useState } from "react";
import { CHANGE_MESSAGE, CHANGE_NAME } from "../constants";

function AttrChangeForm(props) {
    const [value, setValue] = useState("")
    const formDetails = props?.formDetails

    function submit(e) {
        e.preventDefault()
        formDetails.toggleForm()
        setValue("")
        formDetails.submitAction(value)
        
    }

    const formHeader = () => {
        if (formDetails.action_name === CHANGE_NAME) {
            return "New Name"
        } else if (formDetails.action_name === CHANGE_MESSAGE) {
            return "New Message"
        } else {
            return " "
        }
    }
    
    return (
        <div className={`${!formDetails.isFormActive ? "active" : ""} show`}>
            <div className="change-form">
                <div className="change-form-container">
                    <form onSubmit={submit}>
                        <div className="change-form-container-header">{formHeader()}</div>
                        <input
                            className="change-form-container-text"
                            type="text"
                            name="name"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                        <input
                            className="change-form-container-submit"
                            type="submit"
                            value="Confirm"
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AttrChangeForm;
