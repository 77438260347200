

function MintProgress(props) {
    const minted = props.minted
    const maxSupply = props.maxSupply

    return (
        <div className="mint-view-progress">
            Minted: <b>{minted}</b> / {maxSupply}
        </div>
    )
}

export default MintProgress
