import PageFooterSocial from "./PageFooterSocial";


function PageFooter(props) {
    
    return (
        <div className="footer">
            {/* <PageFooterSocial 
                name="discord" 
            /> */}
            <PageFooterSocial 
                name="opensea" 
            />
            <PageFooterSocial 
                name="etherscan" 
            />
            <PageFooterSocial 
                name="twitter" 
            />
            <PageFooterSocial 
                name="instagram" 
            />
        </div>
    )
}

export default PageFooter
