import { Link } from "react-router-dom";

function NavButton(props) {
    const routeName = `/${props.name.toLowerCase()}`
    return (
            <div
                className="app-nav-button"
            >
                <Link 
                    to={routeName}
                    aria-pressed={props.isPressed}
                >
                    {props.name}
                </Link>
            </div>
    );
}

export default NavButton;
