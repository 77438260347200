import { Link } from "react-router-dom";


function GalleryImage(props) {
    return (
        <div className="gallery-image-container">
            <Link to={`/mao/${props.token_id}`}>
                <img src={props.img_src} alt="mao-gif" />
            </Link>
            <div className="gallery-image-container-details">
                Mao #{props.token_id}
            </div>
        </div>
    )
}

export default GalleryImage;
