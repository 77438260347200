import { isAddress } from '@ethersproject/address';
import { Contract } from '@ethersproject/contracts';
import { MAO_ABI, MAO_ADDRESS, FORTUNE_ABI, FORTUNE_ADDRESS, GA_TRACKING_ID_DEV, GA_TRACKING_ID_PROD, DEV_CHAIN_IDS, PROD_CHAIN_IDS } from '../constants';

// Contract Utils
function getSigner(library, account) {
    return library.getSigner(account).connectUnchecked();
}

function getProviderOrSigner(library, account) {
    return account ? getSigner(library, account) : library;
}

function getContract(address, ABI, library, account) {
    return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function getMaoContract(library, account) {
    return getContract(MAO_ADDRESS, MAO_ABI, library, account);
}

export function getFortuneContract(library, account) {
    return getContract(FORTUNE_ADDRESS, FORTUNE_ABI, library, account);
}

// Helpers
export function getRandomInt(maxIntValue) {
    return Math.floor(Math.random() * maxIntValue) + 1; // Tokens start from 1
}

export function getRandomItem(array) {
    if (!Array.isArray(array)) {
		throw new TypeError('Expected an array');
	}

	return array[Math.floor(Math.random() * array.length)];
}

export function truncateAddress(address) {
    return `${address.substring(0,6)}...${address.substring(42-4, 42)}`;
}

export function isValidAddress(address) {
    return isAddress(address)
}


// App Utils
export function getImageUrl(tokenId) {
    return `https://storage.googleapis.com/mao-eth/mao/${tokenId}.gif`
}

export function getOpenSeaAssetUrl(tokenId) {
    return `https://opensea.io/assets/${MAO_ADDRESS}/${tokenId}`
}

export function getRaribleAssetUrl(tokenId) {
    return `https://rarible.com/token/${MAO_ADDRESS}:${tokenId}`
}

export function formatMaoName(maoName) {
    return maoName ? maoName : "Unnamed"
}

export function getTrackingId() {
    return (process.env.NODE_ENV === "development")
        ? GA_TRACKING_ID_DEV
        : GA_TRACKING_ID_PROD
}

export function getOfflinePrice(tokenId) {
    if ((1 <= tokenId) && (tokenId <= 800)) {
        return "0.08"
    } else if ((801 <= tokenId) && (tokenId <= 1600)) {
        return "0.8"
    } else if ((1601 <= tokenId) && (tokenId <= 2400)) {
        return "1.8"
    } else if ((2401 <= tokenId) && (tokenId <= 3200)) {
        return "2.8"
    } else if ((3201 <= tokenId) && (tokenId <= 4000)) {
        return "2.8"
    } else if ((4001 <= tokenId) && (tokenId <= 8888)) {
        return "0.08"
    }

    return "undefined"
}

/**
 * Returns a random tokenId, with an option to re-roll
 * if the price needs to be cheaper
 * @param {int} maxTokenId 
 * @param {boolean} isCheap 
 */
export function getRiggedRandomTokenId(maxTokenId, isCheap) {
    var tokenId = getRandomInt(maxTokenId)

    if (isCheap) {
        // Try 5 times max
        for (var _ in [...Array(5).keys()]) {
            if (getOfflinePrice(tokenId) !== "0.08") {
                tokenId = getRandomInt(maxTokenId)
            }
        }
    }
    
    return tokenId
}

export function getSupportedChainIds() {
    return (process.env.NODE_ENV === "development")
        ? DEV_CHAIN_IDS
        : PROD_CHAIN_IDS
}

export function getEtherscanContractLink(address) {
    return `https://etherscan.io/address/${address}#code`
}
