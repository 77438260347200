import { useEffect, useState } from "react";
import { formatEther } from '@ethersproject/units';
import ReactImageAppear from 'react-image-appear';
import { useWeb3React } from '@web3-react/core';
import { getMaoContract, getRandomInt, getImageUrl, getOfflinePrice, getRiggedRandomTokenId, truncateAddress, getEtherscanContractLink, getRandomItem } from '../utils';
import MintButton from "./MintButton";
import { MAO_ADDRESS, NFT_MAX_SUPPLY, NFT_POOL_SIZE } from "../constants";
import { Link } from "react-router-dom";
import MintProgress from "./MintProgress";
import { BigNumber } from "@ethersproject/bignumber";
import MintIntroText from "./MintIntroText";

const ADJECTIVE = [
    "cutest",
    "quirkiest",
    "luckiest",
    "most random",
    "most gangsta",
]

function MintView() {
    const [isCheap, setIsCheap] = useState(true);
    const [tokenId, setTokenId] = useState(getRiggedRandomTokenId(NFT_POOL_SIZE, isCheap));
    const [tokenPrice, setTokenPrice] = useState("undefined");
    const [ isShowToolTip, setIsShowToolTip ] = useState(false)
    const { active, account, library } = useWeb3React();
    const [maoOwner, setMaoOwner] = useState(null)
    const [minted, setMinted] = useState("???")

    function handleSubmitRandom(e) {
        e.preventDefault();
        const i = getRandomInt(NFT_POOL_SIZE);
        setTokenId(i);

        // Discard cheap token preference on randomization
        if (isCheap) {
            setIsCheap(false)
        }
    }

    function showToolTip(e) {
        e.preventDefault();
        setIsShowToolTip(true)
    }

    function hideToolTip(e) {
        e.preventDefault();
        setIsShowToolTip(false)
    }

    function setOfflinePrice(tokenId) {
        console.warn(`Falling back to offline price...`)
        setTokenPrice(getOfflinePrice(tokenId))
    }

    function getRandomAdjective() {
        return getRandomItem(ADJECTIVE)
    }

    useEffect(() => {
        if (!active) {
            setOfflinePrice(tokenId)
            return // Immediately return
        }

        const contract = getMaoContract(library, account);
        contract.priceByTokenId(tokenId)
                .then(amount => {
                    const etherAmount = formatEther(amount);
                    setTokenPrice(etherAmount);
                })
                .catch(error => {
                    console.error(`Error getting token price - ${error}`)
                    setOfflinePrice(tokenId)
                })

        contract.ownerOf(tokenId)
                .then(address => {
                    setMaoOwner(address)
                })
                .catch(error => {
                    if (error?.message?.includes("nonexistent token")) {
                        console.warn(`No owner found for Mao #${tokenId}`)
                    } else {
                        console.error(error)
                    }
                    setMaoOwner(null)
                })

        contract.totalSupply()
                .then(amount => {
                    const totalSupply = BigNumber.from(amount).toNumber()
                    setMinted(totalSupply)
                })
                .catch(error => {
                    // if (error?.message)
                    console.error(error)
                })
        
    }, [tokenId, active])

    const imageUrl = getImageUrl(tokenId);
    const mintPrice = (tokenPrice === "undefined") ? "" : `Ξ ${tokenPrice}`;

    const imageContainer = (
        <ReactImageAppear
            key={imageUrl} // Required to detect changes
            src={imageUrl}
            animation="fadeIn"
            animationDuration="1.5s"
        />
    )

    function generateToolTips(maoOwner) {
        if (!isShowToolTip) {
            return
        }


        if (!active) {
            return "Connect wallet to mint"
        }

        return maoOwner
            ? `Owned by ${maoOwner}`
            : `Mint for ${mintPrice} ether`
    }

    function imageInfoText(maoOwner) {
        return maoOwner
            ? `Owner: ${maoOwner}`
            : null
    }

    function mintButtonText(maoOwner) {
        return maoOwner ? "Trade on OpenSea" : "Mint!"
    }

    const maoAddressCopy = () => {
        return (
            <a 
                href={getEtherscanContractLink(MAO_ADDRESS)}
                target="_blank"
                rel="noreferrer"
            >
                {truncateAddress(MAO_ADDRESS)}
            </a>
        )
    }

    return (
        <div className="mint-view-container">

            <h1>The {getRandomAdjective()} Fortune Cats on Ethereum!</h1>

            <MintIntroText
                text={<p>
                    Circa May 2021, the first Mao was born - its complete proof
                    of existence was deployed into the digital realm and now
                    occupies the exotic address at {maoAddressCopy()}. 
                </p>}
            />

            <MintIntroText
                text={<p>
                    Once born,
                    every Mao starts accumulating <i>fortunes</i>, which can be 
                    used to change its destiny, or its owner's. If you adopt a Mao,
                    you can also give it a special name or get it to broadcast a 
                    message on Ethereum.
                </p>}
            />

            <br></br>

            <h1>Mint a Mao</h1>

            <MintIntroText
                text={<p>
                    Maos can be <i>minted</i> from 0.08Ξ to 2.8Ξ, there is no bonding
                    curve and the metadata is already fused into the contract. So 
                    keep re-rolling until the perfect Mao appears!
                </p>}
            />


            <div className="mint-view-img-container">
                <Link to={`/mao/${tokenId}`}>
                    <div className="img-wrapper">
                        {imageContainer}
                    </div>
                </Link>
                <div className="mint-view-img-info">
                    {imageInfoText(maoOwner)}
                </div>
            </div>

            <MintProgress 
                minted={minted}
                maxSupply={NFT_MAX_SUPPLY}
            />

            <div className="mint-view-actions">
                <div className="mint-view-actions-tokenid">
                    <b>MAO #{tokenId}</b>
                </div>

                <div className="mint-view-actions-price">
                    {mintPrice}
                </div>

                <div 
                    type="button"
                    className="mint-view-actions-randomize"
                    onClick={handleSubmitRandom}
                >
                    Randomize
                </div>

                <MintButton 
                    tokenId={tokenId} 
                    tokenPrice={tokenPrice}
                    buttonText={mintButtonText(maoOwner)}
                    hideToolTip={hideToolTip}
                    showToolTip={showToolTip}
                    mintEnabled={maoOwner ? false : true}
                />
            </div>

            <div className="mint-view-tooltip">
                <div 
                    className={isShowToolTip ? "displayed" : "notdisplayed"}
                >
                    {generateToolTips(maoOwner)}

                </div>
            </div>

            <br></br>

            <h1>Mint Milestones</h1>

            <MintIntroText
                text={<p>
                    We want minting Mao to be fun and rewarding, it's 
                    a <i>Fortune Cat</i> after all! So, with every significant 
                    milestone, a lucky minter will be rewarded with an Ether airdrop
                    AND a mystery NFT giveaway!
                </p>}
            />

            <MintIntroText
                text={<p> 
                    Here's the reward schedule, minters up to the particular
                    milestone will be included in the random selection:
                    <ul>
                        <li>88 Minted → 0.800Ξ</li>
                        <li>888 Minted → 1.800Ξ</li>
                        <li>1888 Minted → 1.888Ξ</li>
                        <li>2888 Minted → 2.888Ξ</li>
                        <li>3888 Minted → 3.888Ξ</li>
                        <li>4888 Minted → 4.888Ξ</li>
                        <li>5888 Minted → 5.888Ξ</li>
                        <li>6888 Minted → 6.888Ξ</li>
                        <li>7888 Minted → 7.888Ξ</li>
                        <li>8888 Minted → 8.888Ξ</li>
                    </ul>
                    
                </p>}
            />

            <br></br>

            <h1>Project Roadmap</h1>

            <MintIntroText
                text={<p>
                    Once the Mint Roadmap completes, the Project Roadmap then
                    starts kicking in.
                </p>}
            />

            <MintIntroText
                text={<p>
                    In order for Mao to live up to its purpose as Fortune Cats,
                    it needs to keep ushering <i>fortune</i> to its owners. We are designing
                    a few ways for this to happen, some ideas include NFT lotteries,
                    investment DAO, or other products that utilise the generated 
                    $FORTUNE tokens.
                </p>}
            />

            <MintIntroText
                text={<p>
                    With every new use-case, holders' interest will be prioritised and
                    polls will be carried out to survey the general desires of the community.
                </p>}
            />

            <MintIntroText
                text={<p>
                    In the meantime, if you already have a Mao, welcome to 
                    the <i>Fortune 8888</i> club!
                </p>}
            />

        </div>
    );
}

export default MintView;
