import { useEffect, useState } from "react";
import { useWeb3React } from '@web3-react/core';
import { nanoid } from "nanoid";
import { getFortuneContract, getImageUrl, getMaoContract } from '../../utils';

import WalletImage from "./WalletImage";
import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { Link } from "react-router-dom";

function WalletView(props) {
    const { active, account, library } = useWeb3React();
    const [ maoBalance, setMaoBalance] = useState(0);
    const [ ownerTokenIds, setOwnerTokenIds ] = useState([]);
    const [ multipliers, setMultipliers ] = useState([]);
    const [ maoNames, setMaoNames ] = useState([]);
    // const [ hashes, setHashes ] = useState([]);
    const [ fortuneTokens, setFortuneTokens ] = useState([]);
    const [ messages, setMessages ] = useState([]);
    const [ totalFortune, setTotalFortune ] = useState(0);
    const [ fortuneBalance, setFortuneBalance ] = useState(0);

    useEffect(() => {
        if (!active) {
            console.warn("Wallet not connected, skip getting Maos")
            return // Immediately return
        }

        const contract = getMaoContract(library, account);
        const fortuneContract = getFortuneContract(library, account);
        contract.balanceOf(account)
            .then(balance => {
                const intBalance = parseInt(balance);
                setMaoBalance(intBalance);
                if (intBalance > 0) {
                    return intBalance;
                } else {
                    throw new Error("Wallet has no NFT");
                }
            })
            .then(balance => {
                const indices = [...Array(balance).keys()];

                // TokenIds
                const whenTokenIds = indices.map(index => {
                    return contract.tokenOfOwnerByIndex(account, index)
                });
                Promise.all(whenTokenIds).then(tokenIds => {
                    const tokenNumIds = tokenIds.map(i => {
                        return BigNumber.from(i).toNumber();
                    })
                    console.log(`tokenIds: ${tokenNumIds}`);
                    setOwnerTokenIds(tokenNumIds);
                    return tokenNumIds;
                })
                .then(tokenIds => {
                    // Multipliers
                    const whenMultipliers = tokenIds.map(tokenId => {
                        return contract.multiplierByTokenId(tokenId);
                    })
                    Promise.all(whenMultipliers).then(r => {
                        setMultipliers(r)
                    })
                    return tokenIds;
                })
                .then(tokenIds => {
                    // Names
                    const whenNames = tokenIds.map(tokenId => {
                        return contract.nameByTokenId(tokenId);
                    })
                    Promise.all(whenNames).then(r => {
                        setMaoNames(r)
                    })
                    return tokenIds;
                })
                // .then(tokenIds => {
                //     // Hashes
                //     const whenHashes = tokenIds.map(tokenId => {
                //         return contract.hashByTokenId(tokenId);
                //     })
                //     Promise.all(whenHashes).then(r => {
                //         setHashes(r)
                //     })
                //     return tokenIds;
                // })
                .then(tokenIds => {
                    // Accumulated fortune
                    const whenFortuneTokens = tokenIds.map(tokenId => {
                        return fortuneContract.accumulated(tokenId);
                    })
                    Promise.all(whenFortuneTokens).then(r => {
                        const parsedFortune = r.map(f => {
                            return parseFloat(formatEther(f))
                        })
                        setFortuneTokens(parsedFortune)
                        return parsedFortune;
                    })
                    .then(parsedFortune => {
                        const totalFortune = parsedFortune.reduce((total, fortune) => total + fortune)
                        setTotalFortune(totalFortune.toFixed(2));
                    })
                    return tokenIds;
                })
                .then(tokenIds => {
                    // Messages
                    const whenMessages = tokenIds.map(tokenId => {
                        return contract.messageByTokenId(tokenId);
                    })
                    Promise.all(whenMessages).then(r => {
                        setMessages(r)
                    })
                })

            })
            .catch(err => {
                console.error(err);
            })

    }, [active, account, library])

    useEffect(() => {
        if (!active) {
            console.warn("Wallet not connected, skip getting fortune")
            return // Immediately return
        }

        const fortuneContract = getFortuneContract(library, account);
        // Wallet Balance
        fortuneContract.balanceOf(account)
            .then(value => {
                const balance = parseFloat(formatEther(value)).toFixed(2);
                setFortuneBalance(balance)
            })
            .catch(err => {
                console.error(err);
            })
    }, [active, account, library])

    const indices = [...Array(maoBalance).keys()];
    const imgList = indices.map(i => {
        const tokenId = ownerTokenIds[i]? ownerTokenIds[i] : 0
        if (tokenId < 1) {
            return // tokenId begins from 1
        }
        
        const img_id = `img-${nanoid()}`
        const img_src = getImageUrl(tokenId)
        const mao_name = maoNames[i] ? maoNames[i] : "Unnamed"
        const message = messages[i] ? messages[i] : '"Meow"'

        return <WalletImage 
                    key={img_id} 
                    img_src={img_src} 
                    multiplier={multipliers[i]}
                    // hash={hashes[i]}
                    token_id={tokenId}
                    fortune_token={fortuneTokens[i]}
                    mao_name={mao_name}
                    message={message}
                />

    });

    function noNftView() {
        return (
            <div className="wallet-view-no-nft">
                <Link className="wallet-view-no-nft-button" to="/mint">
                    <div className="wallet-view-no-nft-button-text">
                        Mint your first Mao
                    </div>
                </Link>

                <Link className="wallet-view-no-nft-button" to="/gallery">
                    <div className="wallet-view-no-nft-button-text">
                        Discover Maos
                    </div>
                </Link>
            </div>
        )
    }

    return (
        <div className="wallet-view-container">
            <div>
                Claimable: {totalFortune}, Wallet: {fortuneBalance}
            </div>
            {(maoBalance > 0) ? imgList : noNftView()}
        </div>
    );
}

export default WalletView;
