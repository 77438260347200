import { Link } from "react-router-dom";
import { FORTUNE_ADDRESS, MAO_ADDRESS, NFT_POOL_SIZE, SOCIAL_LINKS } from "../../constants";
import { getEtherscanContractLink, getImageUrl, getOpenSeaAssetUrl, getRandomInt, getRaribleAssetUrl } from "../../utils";
import Question from "./Question";

function FaqView() {

    return (
        <div className="faq-view-container">

            <img src="/images/mao-black-bg.png" alt="mao-black-bg" />

            <Question 
                question="How many MAOs are out there?"
                answer={
                    <div className="faq-answer">
                        There will only be 8,888 MAOs ever, and these MAOs will be gradually
                        dropped into a pool before they are mintable.
                    </div>
                }
            />

            <Question 
                question="Where are these artwork stored?"
                answer={
                    <div className="faq-answer">
                        When we create a MAO, we create an image and its corresponding metadata.
                        These will be pinned on the InterPlanetary File System 
                        (<a href="https://ipfs.io/" target="_blank" rel="noreferrer">IPFS</a>), 
                        while the resulting IPFS hashes 
                        (<a href="https://ipfs.io/ipfs/QmbcqDVs4pwkmUHyvShwUoTQFhJQcs1Hp48w2rArraywYe" target="_blank" rel="noreferrer">example</a>) 
                        will then be saved onto the Ethereum blockchain, referenced by a unique token ID. 
                        This subsequently allows you to mint the artwork from 
                        the <Link to="/mint">Mint Page</Link>.
                    </div>
                }
            />

            <Question 
                question="What is the mint price?"
                answer={
                    <div className="faq-answer">
                        MAOs are mintable from 0.08Ξ to 2.8Ξ right now, once a price
                        has been set for a token it NEVER changes. Keep randomizing
                        to discover the right MAO for you, or check out secondary markets
                        like <a href={SOCIAL_LINKS.opensea?.url} target="_blank" rel="noreferrer">OpenSea</a>.
                    </div>
                }
            />

            <Question 
                question="What happens after I mint it?"
                answer={
                    <div className="faq-answer">
                        Once minted, the NFT will be transferred to your wallet and you can start 
                        seeing it in the <Link to="/wallet">Wallet Page</Link>. Since MAO is an ERC721 
                        Non-Fungible Token (NFT) on the Ethereum blockchain, it will automagically
                        appear on digital collectible sites that support ERC721 tokens. Check out Mao #1 on <a href={getOpenSeaAssetUrl(1)} target="_blank" rel="noreferrer">OpenSea</a> and <a href={getRaribleAssetUrl(1)} target="_blank" rel="noreferrer">Rarible</a>!
                    </div>
                }
            />

            <Question
                question="What can I do with MAO?"
                answer={
                    <div className="faq-answer">
                        <p>
                            You can give it a befittingly cute (or <i>evil</i>) name, get it to 
                            publish a message on Ethereum, trade it like any other NFTs, or just 
                            show it off to your friends ;)
                        </p>
                        <p>
                            In the long-term, MAO envisions to be a utility token that keeps 
                            generating <i>fortunes</i> for its holders.
                        </p>
                    </div>
                }
            />

            <br></br>

            <img src={getImageUrl(getRandomInt(NFT_POOL_SIZE))} alt="mao-random-gif" />

            <Question
                question="What is $FORTUNE?"
                answer={
                    <div className="faq-answer">
                        $FORTUNE is an ERC20 token designed to be earnable through your MAOs,
                        and will be used to facilitate operations that modify your MAOs.

                        <br></br><br></br>

                        A freshly minted MAO comes with 888 $FORTUNE, and this claimable amount
                        increases as your MAO generates more $FORTUNE throughout the day
                    </div>
                }
            />

            <Question
                question="How much $FORTUNE can I accumulate?"
                answer={
                    <div className="faq-answer">
                        The daily amount is calculated by: (18 x EARNINGS_MULTIPLIER / 100)

                        <br></br><br></br>

                        where <b>EARNINGS_MULTIPLIER</b> is an upgradable number between <b>100 - 255</b>. 
                        For example, if your MAO has a multiplier of 120, it will earn (18 x 120%) = 21.6
                        tokens daily, this multiplier can be upgraded up to 255 which translates to
                        (18 x 255%) = 45.9 tokens daily.
                    </div>
                }
            />

            <Question 
                question="How much does it cost to modify my MAO?"
                answer={
                    <div className="faq-answer">
                        It costs 88 $FORTUNE to assign a message, 888 to assign a name, 
                        and 8,888 $FORTUNE to upgrade the earnings
                    </div>
                }
            />

            <Question 
                question="Where can I acquire more $FORTUNE?"
                answer={
                    <div className="faq-answer">
                        Since $FORTUNE is an ERC20 token, it is swappable on many compatible
                        DEXs like Uniswap or Sushiswap, just to name a few. More exotic strategies
                        may exist, but we will leave it to you to try things out.
                    </div>
                }
            />

            <Question 
                question="Where are the verified contracts behind all these?"
                answer={
                    <div className="faq-answer">
                        <p style={{textAlign:'center'}}>
                            MAO Contract: <a href={getEtherscanContractLink(MAO_ADDRESS)}><b>{MAO_ADDRESS}</b></a>
                        </p>

                        <p style={{textAlign:'center'}}>
                            Fortune Contract: <a href={getEtherscanContractLink(FORTUNE_ADDRESS)}><b>{FORTUNE_ADDRESS}</b></a>
                        </p>
                    </div>
                }
            />

            <br></br>

            <img src={getImageUrl(getRandomInt(NFT_POOL_SIZE))} alt="mao-random-gif" />

        </div>
    );
}

export default FaqView;
