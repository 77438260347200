import GalleryImage from "./GalleryImage"
import { nanoid } from "nanoid";
import { getImageUrl } from "../../utils";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { NFT_POOL_SIZE } from "../../constants";

const DEFAULT_PAGE_SIZE = 24

function parsePerPage(perPage) {
    const numVal = Number(perPage)
    return (Number.isInteger(numVal) && numVal > 0)
        ? numVal
        : DEFAULT_PAGE_SIZE
}

function parsePage(page) {
    const numVal = Number(page)
    return (Number.isInteger(numVal) && numVal > 0) 
        ? numVal 
        : 1
}

function GalleryPage(props) {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search)
    const _perPage = searchParams.get("perPage"), _page = searchParams.get("page")
    
    const perPage = parsePerPage(_perPage)
    const page = parsePage(_page)
    const tokenIdOffset = (page - 1) * perPage

    const indices = [...Array(perPage).keys()]
    const imgList = indices
        .filter(i => i + tokenIdOffset < NFT_POOL_SIZE)
        .map(i => {
            const tokenId = i + 1 + tokenIdOffset
            const img_id = `img-${nanoid()}`
            const img_src = getImageUrl(tokenId)

            return (
                <div className="column" key={img_id}>
                    <GalleryImage
                        img_src={img_src} 
                        token_id={tokenId}
                    />
                </div>
            )
        })

    function showNextPagePath() {
        const nextPagePath = `/gallery?perPage=${perPage}&page=${page + 1}`
        const totalItems = page * perPage
        if ((0 < totalItems) && (totalItems < NFT_POOL_SIZE)) {
            return (
                <div className="faq-view-container-pagenav-button">
                    <Link to={nextPagePath}>
                        Next →
                    </Link>
                </div>  
            )
        }
    }

    function showPrevPagePath() {
        const prevPagePath = `/gallery?perPage=${perPage}&page=${page - 1}`
        if (page > 1) {
            return (
                <div className="faq-view-container-pagenav-button">
                    <Link to={prevPagePath}>
                        ← Previous
                    </Link>
                </div>  
            )
        }
    }

    return (
        <div className="faq-view-gallery-page">
            <div className="row">
                {imgList}
            </div>

            <div className="faq-view-container-pagenav">
                <div className="faq-view-container-pagenav-buttons">
                    {showPrevPagePath()}
                </div>

                <div className="faq-view-container-pagenav-buttons">
                    {showNextPagePath()}
                </div>
            </div>
        </div>
    )
}

function GalleryView(props) {
    return (
        <div className="faq-view-container">
            <Switch>
                <Route exact path="/gallery" component={GalleryPage} />
            </Switch>
        </div>
    )
}

export default GalleryView
