
function MintIntroText(props) {

    return (
        <div className="mint-view-intro-text">
            {props.text}
        </div>
    )
}

export default MintIntroText
